import styled from 'styled-components';

const ComponentWrapper = styled.div`
  position: relative;
  .text-transition {

  }
  .transitioning-in {
    animation-name: text-transition;
    animation-timing-function: linear;
    position: absolute;
  }
  .transitioning-out {
    animation-direction: reverse;
    animation-name: text-transition;
    animation-timing-function: linear;
    position: absolute;
  }
  @keyframes text-transition {
    0% {
      background-color: red;
      /* color: transparent; */
      opacity: 0;
    }
    100% {
      background-color: transparent;
      /* color: black; */
      opacity: 1;
    }
  }
`;

export default ComponentWrapper;