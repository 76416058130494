import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './ScheduleTicker.css';
import withContext from 'store/withContext';
import { Swiper, Slide } from 'react-dynamic-swiper';
import { Link } from 'gatsby';
import TextTransition from 'components/Global/TextTransition';

class ScheduleTicker extends Component {
  newDate=null;
  lastDate=null;
  constructor(props) {
    super(props);
    this.state = {
      startSlide: 1
    };
    this.tickerRef = React.createRef();
    
  }
  
  next = () => {
    this.tickerRef.current._swiper.slideNext();
  }

  prev = () => {
    this.tickerRef.current._swiper.slidePrev();
  }

  findNextMatch = (arr, time) => {
    const isLargeNumber = (element) => element > time;
    const getIndex = arr.findIndex(isLargeNumber);
    if (getIndex === -1) {
      this.setState({startSlide: arr.length })
    }
    else {
      this.setState({startSlide: getIndex })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.context.schedule !== this.props.context.schedule) {
      this.findNextMatch(this.props.context.schedule.match_list, this.props.context.now);
    }
  }

  render() {
    const { schedule } = this.props.context;

    return (
      <ComponentWrapper className="c-ScheduleTicker">
        <div className="arrow-container prev" onClick={this.prev}><div className="arrow" /></div>
        <Swiper
          ref={this.tickerRef}
          swiperOptions={{
            slidesPerView: 'auto',
            pagination: false,
            allowTouchMove: true,
            initialSlide: this.state.startSlide
          }}
          >
          {schedule && schedule.schedule.map((day) =>
            {
              {this.newDate=new Date(day.date*1000).toLocaleDateString(navigator.language,{month: 'short', day: 'numeric'})!==this.lastDate?true:false}
              {this.lastDate=new Date(day.date*1000).toLocaleDateString(navigator.language,{month: 'short', day: 'numeric'})}
              return (day.matches.map((match, mi) => 
            <Slide key={match.match_id} className={`match ${mi === 0&& this.newDate ? 'long': ''}`}>
              {mi === 0 && this.newDate && <div className="date">{new Date(day.date*1000).toLocaleDateString(navigator.language,{month: 'short', day: 'numeric'})}</div>}
              <div className="match-info">
                <div className="match-teams">
                  <div className={`team 1 ${(match.winning_team && match.winning_team !== match.team_1_shortname) ? 'loss' : ''}`}>
                    <Link to={`/teams/${match.team_1_shortname.toLowerCase()}`} className="logo-container" style={match.team_1_shortname === 'TBD' ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.props.context.darkTheme ? 'dark/' : ''}${match.team_1_shortname}.png`} /></Link>
                    <Link to={`/teams/${match.team_1_shortname.toLowerCase()}`} className="detail-link" style={match.team_1_shortname === 'TBD' ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>
                    <div className="name">{match.team_1_shortname}</div>
                    </Link>
                  </div>
                  <div className={`team 2 ${(match.winning_team && match.winning_team !== match.team_2_shortname) ? 'loss' : ''}`}>
                    <Link to={`/teams/${match.team_2_shortname.toLowerCase()}`} className="logo-container" style={match.team_2_shortname === 'TBD' ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.props.context.darkTheme ? 'dark/' : ''}${match.team_2_shortname}.png`} /></Link>
                    <Link to={`/teams/${match.team_2_shortname.toLowerCase()}`} className="detail-link" style={match.team_2_shortname === 'TBD' ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>
                    <div className="name">{match.team_2_shortname}</div>
                     
                    </Link>
                  </div>
                </div>
                <div className="match-scores splr">
                  <div className={`score-line ${(match.winning_team && match.winning_team !== match.team_1_shortname) ? 'loss' : ''}`}>
                    {(match.in_progress || match.winning_team) && 
                    <div className="score team-score">{match.team_1_score}</div>
                    }
                  </div>
                  <div className={`score-line ${(match.winning_team && match.winning_team !== match.team_2_shortname) ? 'loss' : ''}`}>
                    {(match.in_progress || match.winning_team) && 
                    <div className="score team-score">{match.team_2_score}</div>
                    }
                  </div>
                  
                </div>
                
                      
                {(!match.winning_team && match.in_progress === 0)  &&
                <Link className="time" to={`/matches/${match.match_id}`}>
                  <>
                  <p>{new Date(match.time*1000).toLocaleTimeString(navigator.language,{ hour: 'numeric', minute:'2-digit',hour12: true, timeZoneName: 'short' })}</p>
                  
                  </>
                </Link>
                }
                {(!match.winning_team && match.in_progress === 1)  &&
                <a className="time final" href={schedule.stream_url} target="_blank">
                  <p>Live <span><svg height="100%" width="100%">
                    <circle cx="50%" cy="50%" r="50%" strokeWidth="1" stroke-color="white" />
                    </svg></span>
                  </p>
                </a>
                }
                {(match.winning_team && match.in_progress === 0 && match.playlist_url) &&
                <a className="time" href={match.playlist_url} target="_blank">
                  <p className="finalLink">Final</p>
                </a>
                }
                {(match.winning_team && match.in_progress === 0 && !match.playlist_url) &&
                <Link className="time" to={`/matches/${match.match_id}`} target="_blank">
                  <p className="finalLink">Final</p>
                </Link>
                }
              </div>
            </Slide>
            ))}
          )}
        </Swiper>
        <div className="arrow-container next" onClick={this.next}><div className="arrow" /></div>
      </ComponentWrapper>
    );
  }
}

export default withContext(ScheduleTicker);