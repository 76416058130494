import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  font-size: 1.5rem;
  height: 9.5rem;
  position: relative;
  width: 100%;
  .swiper-container {
    width: calc(100vw - 10rem);
  }
  @media only screen and (max-width: 920px) {
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
    font-size: 0.8rem;
    height: 5.7rem;
    order: 2;
    top: 3.2rem;
    .swiper-container {
      width: 100vw;
    }
  }
  .arrow-container {
    align-items: center;
    background: var(--background);
    border: 1px solid var(--border);
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 0 1.8rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    @media only screen and (max-width: 920px) {
      display: none;
    }
    &.next {
      left: auto;
      right: 0;
      transform: rotate(180deg);
    }
  }
  .arrow {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right: 12px solid var(--text);
    height: 0;
    pointer-events: none;
    width: 0;
  }
  .match {
    align-items: center;
    background-color: var(--ticker-background);
    border: 1px solid var(--border);
    border-left: none;
    display: flex;
    height: 9.5rem;
    width: 25rem;
    &.long {
      width: 30rem;
    }
    @media only screen and (max-width: 920px) {
      height: 5.7rem;
      width: 13rem;
      &.long {
        width: 15.5rem;
      }
    }
  }
  .match-info {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .match-scores {
    display: flex;
    justify-content: center;
    flex-direction:column;
    width: 100%;
  }
  .date {
    align-items: center;
    background: var(--secondary-background);
    border-right: 1px solid var(--border);
    color: var(--text);
    display: flex;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    max-width: 3rem;
    padding: 0 1rem;
    text-align: center;
    @media only screen and (max-width: 920px) {
      padding: 0 0.3rem;
    }
  }
  .logo-container {
    margin: 0.3rem 1rem;
    height: 3rem;
    width: 3rem;
    img {
      width: 3rem;
    }
    @media only screen and (max-width: 920px) {
      margin: 0 0.5rem;
      pointer-events: none;
      height: 2rem;
      img {
        height: 1.8rem;
        width: 1.8rem;
      }
    }
  }
  .team, .score-line {
    align-items: center;
    display: flex;
    font-weight: bold;
    margin: 0.5rem 0.5rem 0.5rem 1.5rem;
    @media only screen and (max-width: 920px) {
      font-size: 0.8rem;
    }
    &.loss {
      .detail-link {
        opacity: 0.7;
      }
      .team-score{
        opacity: 0.7;
      }
    }
  }
  .score-line{
    margin: 0.5rem;
    height: 3rem;
    cursor:pointer;
  }
  .team-score{
      margin: 0.3rem 0rem;
      width: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  .detail-link {
    color: var(--text);
    display: flex;
    text-decoration: none;
  }
  .name {
    margin: 0 1.5rem;
    min-width: 4rem;
    @media only screen and (max-width: 920px) {
      margin: 0 0.5rem;
      min-width: 2rem;
    }
  }
  .time {
    align-items: center;
    color: var(--text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
    min-width: 6.7rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    @media only screen and (max-width: 920px) {
      margin: 0 0.5rem;
      min-width: 2rem;
    }
    &.final {
      color: #e30000;
      p {
        align-items: center;
        display: flex;
        margin-left: 1rem;
      }
      
      span {
        border: 1px solid var(--border);
        border-radius: 50%;
        display: flex;
        align-items: center;
        width: 14px;
        height: 14px;
        background: transparent;
        justify-content: center;
        margin-left: 1rem;
        @media only screen and (max-width: 920px) {
          height: 9px;
          margin: -2px 0 0 0.5rem;
          width: 9px;
        }
      }
    }
    svg {
      animation: blink 1.3s infinite;
      fill: #e30000;
      stroke: #FFF;
      stroke-width: 1;
      @media only screen and (max-width: 920px) {
        transform: scale(0.6);
      }
    }
    p {
      line-height: 1.3;
      transition: color 0.2s;
      &.finalLink:hover {
          color: var(--primary);
      }
    }
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;