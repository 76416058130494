import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentWrapper from './TextTransition.css';


class TextTransition extends Component {

  constructor(props) {
    super(props)

    this.state = {
      transitioning: false,
      currentValue: this.props.textValue,
      futureValue: undefined
    };
  }

  transitionText = () => {
    setTimeout(() => {
      this.setState({
        currentValue: this.props.textValue,
        futureValue: null,
        transitioning: false
      });
    }, this.props.duration);
  }

  componentDidMount() {
    this.setState({currentValue: this.props.textValue});
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.textValue !== this.props.textValue) {
      this.setState({
        futureValue: this.props.textValue,
        transitioning: true
      });
      this.transitionText();
    }
  }

  render() {
    return (
      <ComponentWrapper>
        {!this.state.transitioning &&
        <span style={{animationDuration: `${(this.props.duration) / 1000}s`}} className={`text-transition ${this.props.className}`}>{this.state.currentValue}</span>
        }
        {this.state.transitioning &&
        <>
        <span style={{animationDuration: `${(this.props.duration) / 1000}s`}} className={`text-transition ${this.props.className} transitioning-out`}>{this.state.currentValue}</span>
        <span style={{animationDuration: `${(this.props.duration) / 1000}s`}} className={`text-transition ${this.props.className} transitioning-in`}>{this.state.futureValue}</span>
        </>
        }
      </ComponentWrapper>
    );
  }
}

TextTransition.propTypes = {
  textValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  className: PropTypes.string,
  duration: PropTypes.number.isRequired
}

export default TextTransition;