import React, { Component } from 'react';
import { render } from 'react-dom';
import { Link } from 'gatsby';
import { ComponentWrapper } from './Footer.css';

var smoothScroll = {
	timer: null,

	stop: function () {
		clearTimeout(this.timer);
	},

	scrollTo: function (id, callback) {
		var settings = {
			duration: 1000,
			easing: {
				outQuint: function (x, t, b, c, d) {
					return c*((t=t/d-1)*t*t*t*t + 1) + b;
				}
			}
		};
		var percentage;
		var startTime;
		var node = document.getElementById('root');
		var nodeTop = node.offsetTop;
		var nodeHeight = node.offsetHeight;
		var body = document.body;
		var html = document.documentElement;
		var height = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);
		var windowHeight = window.innerHeight
		var offset = window.pageYOffset;
		var delta = nodeTop - offset;
		var bottomScrollableY = height - windowHeight;
		var targetY = (bottomScrollableY < delta) ?
			bottomScrollableY - (height - nodeTop - nodeHeight + offset):
			delta;

		startTime = Date.now();
		percentage = 0;

		if (this.timer) {
			clearInterval(this.timer);
		}

		function step () {
			var yScroll;
			var elapsed = Date.now() - startTime;

			if (elapsed > settings.duration) {
				clearTimeout(this.timer);
			}

			percentage = elapsed / settings.duration;

			if (percentage > 1) {
				clearTimeout(this.timer);

				if (callback) {
					callback();
				}
			} else {
				yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
				window.scrollTo(0, yScroll);
				this.timer = setTimeout(step, 10);
			}
		}

		this.timer = setTimeout(step, 10);
	}
};

var smoothScroll = {
	timer: null,

	stop: function () {
		clearTimeout(this.timer);
	},

	scrollTo: function (id, callback) {
		var settings = {
			duration: 1000,
			easing: {
				outQuint: function (x, t, b, c, d) {
					return c*((t=t/d-1)*t*t*t*t + 1) + b;
				}
			}
		};
		var percentage;
		var startTime;
		var node = document.getElementById('root');
		var nodeTop = node.offsetTop;
		var nodeHeight = node.offsetHeight;
		var body = document.body;
		var html = document.documentElement;
		var height = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);
		var windowHeight = window.innerHeight
		var offset = window.pageYOffset;
		var delta = nodeTop - offset;
		var bottomScrollableY = height - windowHeight;
		var targetY = (bottomScrollableY < delta) ?
			bottomScrollableY - (height - nodeTop - nodeHeight + offset):
			delta;

		startTime = Date.now();
		percentage = 0;

		if (this.timer) {
			clearInterval(this.timer);
		}

		function step () {
			var yScroll;
			var elapsed = Date.now() - startTime;

			if (elapsed > settings.duration) {
				clearTimeout(this.timer);
			}

			percentage = elapsed / settings.duration;

			if (percentage > 1) {
				clearTimeout(this.timer);

				if (callback) {
					callback();
				}
			} else {
				yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
				window.scrollTo(0, yScroll);
				this.timer = setTimeout(step, 10);
			}
		}

		this.timer = setTimeout(step, 10);
	}
};

class Footer extends Component {

    handleClick = () => {
			window.scroll({
				top: 0, 
				left: 0, 
				behavior: 'smooth' 
			 });
    }

    render(){
		const { t } = this.props;

        return (
          <ComponentWrapper className="c-footer">
            <section className="footer">
              <div className="top">
                <div className="top__logo">
                  <Link to="/">
                    <img src="https://webcdn.hirezstudios.com/hirez-studios/shared/smite-nav-logo.png" alt="SMITE" />
                  </Link>
                </div>
                <div className="top__social">
                  <a target="_blank" href="https://twitter.com/SmitePro"><i className="icon icon-twitter"></i></a>
                  <a target="_blank" href="https://www.facebook.com/officialsmitepro"><i className="icon icon-facebook"></i></a>
                  <a target="_blank" href="https://www.youtube.com/Smitepro"><i className="icon icon-youtube"></i></a>
                  <a target="_blank" href="https://www.twitch.tv/smitegame"><i className="icon icon-twitch"></i></a>
                </div>
                
                <div className="top__go-up">
                  <a onClick={this.handleClick}>Back To Top<i className="icon-keyboard_arrow_up">▲</i></a>
                </div>
              </div>
              <div className="middle">
                <div className="middle__links">

                </div>
              </div>
              <div className="bottom">
                <div className="hirez">
									<div className="hirez__logo">
										<a href="https://www.hirezstudios.com/" target="_blank">
											<img src="https://webcdn.hirezstudios.com/smite/v3/img/hirez-logo.png" />
										</a>
                  </div>
                  <div className="hirez__links">
                    <a href="https://www.hirezstudios.com/games/" target="_blank">Games</a>
                    <a href="https://www.hirezstudios.com/careers" target="_blank">Careers</a>
                    <a href="https://www.hirezstudios.com/press" target="_blank">Press</a>
                    <a href="https://webcdn.hirezstudios.com/hirez-studios/legal/PaladinsStrikeEndUserAgreement-(2017-08-22).pdf" target="_blank">EULA</a>
                    <a href="https://www.hirezstudios.com/privacy">Privacy Policy</a>
                  </div>
                </div>
                <div className="bottom__copyright">
                  &copy; {new Date().getFullYear()} Hi-Rez Studios, Inc. All Rights Reserved. SMITE is a registered trademark of Hi-Rez Studios, Inc. All RIghts Reserved.
                </div>
                <div className="ga-film">
                  <a href="https://Georgia.org/film" target="_blank">
                    <img src="https://webcdn.hirezstudios.com/studios/georgia-peach.png" alt="Georgia Film" />
                  </a>
                </div>
              </div>
            </section>
          </ComponentWrapper>
        );
    }
}

export default Footer;