import React from 'react';
import { Link } from 'gatsby';
import { ComponentWrapper } from './LittleBuddy.css';

const LittleBuddy = () => {
	return (
    <ComponentWrapper className={`c-LittleBuddy`}>
      <Link to={'/schedule'}>Schedule</Link>
      <Link to={'/scores'}>Scores</Link>
      <Link to={'/standings'}>Standings</Link>
      <Link to={'/stats'}>Stats</Link>
    </ComponentWrapper>
	); 
};

export default LittleBuddy;